<template>
  <div class="parseV1" contenteditable="true" v-html="content"></div>
</template>

<script>
export default {
  name: "parseV1",
  props: {
    content: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.parseV1 {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  /deep/img {
    width: 100% !important;
  }
}
</style>
