<template>
  <div class="cardV1">
    <img
      class="cardV1_dot"
      v-if="mail_type == 1"
      :src="require('@STATIC/image/icons/ziti.png')"
    />
    <div class="cardV1_head">
      <div>
        <div>
          <div class="rmb">¥</div>
          {{ market_price }}
        </div>
        <div>原价 ¥{{ product_price }}</div>
      </div>
      <div class="sale_number">已销{{ sales_number }}</div>
    </div>
    <div class="cardV1_discount" style="margin-right: 5px" v-if="deduct > 0">
      人生券最高抵扣¥{{ deduct }}元
    </div>
    <div class="cardV1_discount" v-if="mail_type == 1">次日可提货</div>
    <div class="cardV1_description">
      {{ title }}
    </div>
    <div
      class="cardV1_willSend"
      style="margin-top: 20px"
      v-if="mail_type == 2 && show_will_send.length > 0"
    >
      预计于<span class="cardV1_willSend_span">{{ show_will_send }}</span
      >前发货
    </div>
    <div class="cardV1_willSend" v-if="commission_money > 0">
      商品成功购买后，最高可获得佣金<span class="cardV1_willSend_span">{{
        commission_money
      }}</span
      >元
    </div>
    <div class="cardV1_tip" v-if="return_exchange == 1">
      提示：该商品属于定制商品，下单后不可退换
    </div>
  </div>
</template>

<script>
export default {
  name: "cardV1",
  props: {
    mail_type: null,
    title: {
      type: String,
      default: "",
    },
    product_price: {
      type: [String, Number],
      default: "",
    },
    market_price: {
      type: [String, Number],
      default: "",
    },
    sales_number: {
      type: [String, Number],
      default: 0,
    },
    deduct: {
      type: [String, Number],
      default: 0,
    },
    return_exchange: {
      type: Number,
      default: 0, //0 可以退款 1不可以退款
    },
    show_will_send: {
      type: [String, Number],
      default: "",
    },
    commission_money: {
      type: [String, Number],
      default: 0,
    },
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
@include b(cardV1) {
  position: relative;
  box-sizing: border-box;
  padding: 20px 15px;
  width: 345px;
  background: #ffffff;
  border-radius: 6px;
  @include e(dot) {
    position: absolute;
    right: 0;
    top: 0;
    width: 39px;
    height: 39px;
  }
  @include e(head) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a2a2a2;
    & > div:first-of-type {
      display: flex;
      align-items: center;
      & > div:first-of-type {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #c10000;
        display: flex;
        align-items: center;

        .rmb {
          font-size: 16px;
          margin-right: 2px;
        }
      }
      & > div:last-of-type {
        margin-left: 10px;
        text-decoration: line-through;
      }
    }

    .sale_number {
      margin-right: 10px;
    }
  }
  @include e(discount) {
    box-sizing: border-box;
    display: inline-block;
    margin-top: 10px;
    border: 1px solid #f46a17;
    padding: 4px 7px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #eb6100;
  }

  @include e(willSend) {
    box-sizing: border-box;
    display: inline-block;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000;

    @include e(span) {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: bold;
      color: #ff0000;
      margin: 0 5px;
    }
  }

  @include e(description) {
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  @include e(tip) {
    margin-top: 13px;
    height: 29px;
    background: #fff9ec;
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    padding: 0 10px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #df8621;
  }
}
</style>
