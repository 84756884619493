<template>
  <div class="footbarV1">
    <div class="footbarV1_row">
      <label class="footbarV1_col" @click.stop="follow">
        <img
          :src="
            isFollow
              ? require('@STATIC/image/icons/love-active.png')
              : require('@STATIC/image/icons/love.png')
          "
        />
        <span :class="{ 'footbarV1_row-active': isFollow }">收藏</span>
      </label>
      <label
        class="footbarV1_col"
        v-debounce="['click', goCar, 500]"
        @touch.prevent
      >
        <img src="@STATIC/image/icons/car.png" />
        <span>购物车</span>
      </label>
    </div>
    <div>
      <button class="footbarV1_btn" @click.stop="addCar" v-if="!disableCart">加入购物车</button>
      <button class="footbarV1_btn" @click.stop="buy">立即购买</button>
    </div>
  </div>
</template>

<script>
/**
 * 购物车底部
 */
export default {
  name: "footbarV1",
  props: {
    isFollow: {
      type: Boolean,
      default: false,
    },
    disableCart: {
      type: Boolean,
      default:false
    }
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {
    follow() {
      this.$emit("follow");
    },
    goCar() {
      this.$emit("goCar");
    },
    addCar() {
      this.$emit("addCar");
    },
    buy() {
      this.$emit("onBuy");
    },
  },
};
</script>

<style lang="scss" scoped>
@include b(footbarV1) {
  box-sizing: border-box;
  padding: 0 15px;
  width: 375px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 0px 0px 0px rgba(209, 209, 209, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include e(row) {
    margin-left: 11px;
    width: 78px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 2px 0 4px 0;
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      & > img {
        width: 16px;
        height: 16px;
      }
      &:active {
        opacity: 0.7;
      }
    }
    @include m(active) {
      color: #f46a17 !important;
    }
  }
  & > div:last-of-type {
    display: flex;
    align-items: center;
  }
  @include e(btn) {
    width: 107px;
    height: 36px;
    background: #ffece1;
    outline: 0;
    border: 0;
    border-radius: 18px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #f46a17;
    &:active {
      opacity: 0.7;
    }
    &:last-of-type {
      margin-left: 10px;
      background: linear-gradient(135deg, #ffb56d 0%, #ff8116 100%);
      color: #ffffff;
    }
  }
}
</style>
